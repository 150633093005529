import React, { useEffect, useLayoutEffect, useState } from 'react'
import './albumSelection.css'
import { useStateContext } from '../../context/StateContext'
import Navbar from '../../components/Navbar/Navbar'
import { getAuthToken, getQueryVariable, getAPIUrl, token_url, getLoginPage, isAdmin, catchError, globalLoader, get_platform_bg_color } from '../../script/util'
import { Card, CardHeader, CardBody, Spinner, CardFooter, Image, Stack, Divider, Heading, Text, background, Center } from '@chakra-ui/react'
import { VscOpenPreview } from "react-icons/vsc";
import { BiLockAlt, BiImage } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { AiFillHome } from 'react-icons/ai'
import axios from 'axios'
import StoreToken from '../../components/StoreToken'
import { Link, useNavigate } from 'react-router-dom'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    Button,
    Box
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
const AlbumSelection = () => {
    document.title = "Album Selection | EventGraphia";
    const navigate = useNavigate()
    const toast = useToast({
        position: "bottom",
        containerStyle: {
            backgroundColor: "black",
            maxWidth: "100%",
        },
    })
    const toastIdRef = React.useRef()
    const lockRef = React.useRef()

    function addToast(text) {
        toastIdRef.current = toast({
            containerStyle: {
                width: "100px",
                padding: "3px",
                color: "white"
            },
            render: () => (
                <Box fontSize='18px' color='white' p={3} mr={0} m={0} bg='black' width="300px" textAlign='center'>
                    {text}
                </Box>
            )
        })
    }
    const { isLoggedin, setisLoggedin } = useStateContext();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    const [homeUrl, setHomeUrl] = useState()
    const bid = getQueryVariable('id');
    const [draft, setdraft] = useState()
    const [isAlertOpen, setisAlertOpen] = useState(false)
    const [workID, setworkID] = useState(null)
    const [isConfirm, setisConfirm] = useState(false)
    const { storeToLocal } = StoreToken()
    useLayoutEffect(() => {
        storeToLocal()
    }, [])



    const getDrafts = () => {
        axios({
            method: 'get',
            url: getAPIUrl("/api/work/draft-album/booking/" + bid + "/"),
            headers: {
                Authorization: getAuthToken(),
            },
            dataType: "json",
            contentType: "application/json; charset=utf-8",
        }).then(function (res) {
            if (res.data.success) {
                setdraft(res.data.data);
                setHomeUrl(res?.data?.home_url)
            }
        }).catch((error) => {
            catchError(error)
        });
    }
    useEffect(() => {
        getDrafts();
    }, [])

    const lock = (work_id) => {
        setworkID(work_id);
        setisAlertOpen(true)

        // lockAPI(work_id)
    }

useEffect(()=>{
    const lockAPI = () => {
        setisAlertOpen(false);
        isConfirm && axios({
            method: 'put',
            url: getAPIUrl("/api/work/my/" + workID + "/lock/"),
            headers: {
                Authorization: getAuthToken(),
            },
            dataType: "json",
            contentType: "application/json; charset=utf-8",
        }).then(function (res) {
            if (res.data.success) {
                getDrafts();
                setisConfirm(false);
                addToast(res.data.message)
                // alert("Locked Successfully!")
            } else {
                setTimeout(()=>{
                    window.location.reload()
                },1500)
                addToast(res.data.message)
            }
        }).catch((error)=>{
            catchError(error)
        });
    }
    lockAPI()
},[isConfirm === true])
    useEffect(()=>{
        !bid && navigate('/')
    },[])
    const Alert = () => {
        return (
            <AlertDialog
                isOpen={true}
                onClose={() => { setisAlertOpen(false) }}
            >
                <AlertDialogOverlay
                    bg='none'
                    backdropFilter='auto'
                    backdropInvert='15%'
                    backdropBlur='2px'
                >
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Lock Album
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => { setisAlertOpen(false); setisConfirm(false) }}>
                                Cancel
                            </Button>
                            <Button ref={lockRef} style={{ backgroundColor: "#319795", color: "white" }} onClick={() => { setisConfirm(true) }} ml={3}>
                                Lock
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        )
    }
    return (
        <>
            {isLoggedin ?
                <>
                    {!localStorage.getItem('eventGraphiaToken') && getLoginPage()}
                    <Navbar title={"Album Selection (" + bid + ")"} location={`/?id=${bid}`} />
                    <div style={{backgroundColor: window.innerWidth > 1200 && get_platform_bg_color(), paddingTop: window.innerWidth<=1200 && '10px'}} className= {`${(window.innerWidth > 1200 ? 'topSwitchTabs' : 'bottomSwitchTabs')}`}>
                              {homeUrl && <p style={{position:window.innerWidth>1200 && "absolute", left:"60px"}}><Link to={homeUrl}><AiFillHome size={30}/></Link></p>}
                              {<p className="tabsPara" style={{borderBottom: (window.innerWidth > 1200 ? '3px solid white' : '3px solid black'), display: window.innerWidth<=1200 && 'none'}} >
                                  <span style={{fontSize: window.innerWidth<600 && '15px'}}>Album Selection</span>
                              </p>}
                          </div>
                    {draft ?
                        <div className="album-selection" style={{paddingTop: window.innerWidth > 1200 ? "200px" : "80px", paddingBottom: "100px"}}>
                            {window.innerWidth <= 1200 && <div style={{paddingTop:"25px", fontWeight:"600"}}>Album Selection</div>}
                            <div className="album-sel-cards">
                                {draft?.map((dd, index) => {
                                    return (<>
                                        <Card key={dd.work_id} className='album-sel-card'>
                                            <CardBody>

                                                <div className="card-img-al-sel" style={{ backgroundImage: `url(${dd.cover_image})`, backgroundSize: 'cover', width: '100%', height: '50vh' }}>



                                                </div>
                                                <span className='card-img-al-sel-overlay'><BiImage />{dd.photo_count}</span>
                                                <Stack mt='6' spacing='3'>
                                                    <Heading size='md'>{dd.title}</Heading>
                                                    <Text pb='2'>
                                                        {dd.subtitle}
                                                    </Text>
                                                </Stack>
                                                <Divider />
                                                <div className='card-footer-al-sel'>
                                                    {dd.web_actions?.map((ac, index) => {
                                                        return <>

                                                            {ac.action_type === "REVIEW" ? <Link to={token_url(`${process.env.REACT_APP_STAG_URL}/${ac.url}`)} className="review-card hollow-btn"><VscOpenPreview />Review</Link > : ""}
                                                            {ac.action_type === "VIEW" ? <Link to={token_url(`${process.env.REACT_APP_STAG_URL}/${ac.url}`)} className="view-card hollow-btn"><AiOutlineEye />View</Link > : ""}
                                                            {ac.action_type === "REVIEW" ? <button onClick={() => { !isAdmin() && lock(dd.work_id) }} className="lock-card hollow-btn"><BiLockAlt />Lock</button> : ""}

                                                        </>
                                                    })}
                                                </div>
                                                {isAlertOpen ? <Alert /> : ""}

                                            </CardBody>
                                        </Card>

                                    </>);
                                })}
                            </div>
                        </div> : <div className='fullpage-overlay'>
                            {globalLoader()}
                        </div>
                    }
                </> : <div className='fullpage-overlay'>
                    {globalLoader()}
                </div>}

        </>
    )
}

export default AlbumSelection