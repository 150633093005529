import * as CONST from "./const";
import axios from "axios";
import { Spinner } from '@chakra-ui/react'
import wg_logo_black from "../logos/WG Black.png"
import wg_logo_white from "../logos/WG White.png"
import eg_logo_black from "../logos/eg_logo_Black.png"
import eg_logo_white from "../logos/eg_logo_white.png"
import shareIcon from '../images/share.png'
import uploadIcon from '../images/upload.png'
import albumIcon from '../images/albumSelection.png'

export function getQueryVariable(name) {
  var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}
export function getLoginPage(data, state) {
  // data should be in stringified form
  // state should be in stringified form

  var url = CONST.S_URL + "/login";
  var next = {};
  var url_data = {};
  var curr_url_data = getAllUrlParams(window.location.href);
  if (data) {
    url_data["data"] = data;
  }
  if (state) {
    url_data["state"] = state;
  }
  url_data = Object.assign({}, url_data, curr_url_data);
  next["next_url"] = window.location.origin + window.location.pathname;
  next["url_data"] = url_data;
  url += "?next=" + JSON.stringify(next);
  window.location = url;
}
export function GetProfile(callback) {
  // return
  // const { userDetails, setuserDetails } = useStateContext();

  var config = {
    method: "get",
    url: getAPIUrl("/api/auth/profile/"),
    cache: false,
    headers: {
      Authorization: getAuthToken(),
    },
  };
  axios(config)
    .then(async function (res) {
      const tp = await res.data;
      if (tp.success) {
        var auth_dict = {
          eventGraphiaName: tp.name,
          eventGraphiaEmail: tp.email,
          eventGraphiaPhone: tp.phone_no,
          eventGraphiaCity: tp.user_city,
          eventGraphiaInitials: tp.name_initials
        };
        localStorage.setItem("auth_dict", JSON.stringify(auth_dict));
        if (callback) callback();
      }
    })
    .catch(function (tp) {
      getLoginPage();
      console.log(tp.message);
    });
}
export function getAllUrlParams(url) {
  // get query string from url (optional) or window
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];

    // split our query string into its component parts
    var arr = queryString.split("&");

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split("=");

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === "undefined" ? true : a[1];

      if (!typeof paramValue === "string")
        paramValue = JSON.stringify(paramValue);
      if (paramName) obj[paramName] = paramValue;
    }
  }

  return obj;
}
export function getAPIUrl(x) {
  return CONST.S_URL + x;
}

export function getAuthToken() {
  return "Token " + localStorage.getItem("eventGraphiaToken");
}

export function getLogoutFunctionality(e) {
  var logout = {
    method: "get",
    url: getAPIUrl("/api/auth/logout/"),
    headers: {
      Authorization: getAuthToken(),
    },
    contentType: "application/json; charset=utf-8",
  };

  axios(logout).then(function (response) {
    if (response.data.success) {
      localStorage.clear();
      getLoginPage();
    } else {
      alert(response.message);
    }
  });
}

export function isAdmin() {
  var status = process.env.REACT_APP_IS_ADMIN === "true";
  return status;
}

export function token_url(pre_url) {
  var url = new URL(pre_url);
  var token = localStorage.getItem("eventGraphiaToken")
  if (!token) {
    getLoginPage();
  }
  url.searchParams.set("token", token);
  return url.toString();
}

export function quotation_string(index) {
  var string1 = `Proposal `;
  var string2 = `v${index}`;
  var string = [string1, string2];
  return string;
}


export function getWhatsappShareUrl(e) {
  const shareLink = `https://wa.me/?text=${e}`
  return shareLink
}

export function globalLoader(size) {
  const defaultSize = 'xl'
  return (<Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size={size ? size : defaultSize} />)
}

export function isMobile() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
}

export function isTouch() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

export function defaultQuality() {
  const quality = 1080
  return quality
}

export class Platform {
  static get EG() {
      return 'eventgraphia';
  }

  static get WG() {
      return 'wedgraphia';
  }

  static get PLATFORM_LIST() {
      return [Platform.EG, Platform.WG];
  }

  static get currentPlatform() {
      return process.env.REACT_APP_PLATFORM;
  }

  static get isEG() {
      return Platform.currentPlatform === Platform.EG;
  }

  static get isWG() {
      return Platform.currentPlatform === Platform.WG;
  }
}

export function get_logo(black=false) {
  if(Platform.isEG){
    if(black) {
      return eg_logo_black
    }
    return eg_logo_white
  } else {
    if(black){
      return wg_logo_black
    }
    return wg_logo_white
  }
}

export function get_platform_bg_color() {
  if(Platform.isEG){
    return "#9f7866"
  } else {
    return "#544d44"
  }
}

export function get_platform_action_color() {
  if(Platform.isEG){
    return "#0d7575"
  } else {
    return "#0d7575"
  }
}

export const downloadReceipt = (installment_id, filename) => {
  
  axios({
      method: 'get',
      url: getAPIUrl(`/api/payment/v2/installment/${installment_id}/receipt/`),
      headers: {
          Authorization: getAuthToken(),
          'Content-Type': 'application/json; charset=utf-8'
      },
      responseType: 'blob' 
  })
  .then(response => {
      if(response.headers['x-filename']){
        filename =  response.headers['x-filename']
      }
      else if(filename){
        filename = filename;
      }else{
        filename = 'receipt.pdf'
      }
      const blob = new Blob([response.data], { type: 'application/pdf' });
      return { blob, filename };
  })
  .then(({ blob, filename }) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
  })
  .catch(error => console.error('Error downloading the PDF:', error));
}

export function getCurrDateTime() {
  const currentDate = new Date();
  const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Asia/Kolkata'
  };
  return currentDate.toLocaleDateString('en-IN', options);
}

export function termsConditions(isPDF) {
  const company = Platform.isEG ? 'EventGraphia' : 'WedGraphia';
  const termsHTML = [
    {
        heading: "Final Product :",
        text: ` The client acknowledges that they are engaging ${company} for its specialized technical and artistic expertise in photography and videography.`+
              ` As such, ${company} reserves the exclusive right to edit and curate the captured images and videos in a manner that aligns with its professional`+
              ` judgment and creative vision. While ${company} will make every effort to document the event comprehensively, it is understood that not every guest`+
              ` or moment may be captured or included in the final edited images or videos. ${company} does not guarantee that every individual attending the event`+
              ` will be photographed or featured in the final deliverables, as the selection of content is subject to artistic discretion and the practical limitations`+
              ` of the event coverage.`
    },
    {
        heading: "Copyright :",
        text: ` ${company} retains the perpetual right, as granted under the Indian Copyright Act, to use the photographs and videos produced during the event for promotional,`+
              ` marketing, and advertising purposes. This includes, but is not limited to, showcasing the work on ${company}'s official website, social media platforms, portfolios,`+
              ` and in other digital or print media channels. The client acknowledges that these materials may be used without further notification or compensation, and grants`+
              ` ${company} the necessary permissions to utilize the content in this manner. ${company} will ensure that any sensitive or private moments are handled with discretion`+
              ` and respect during the selection and use of images or videos for publicity.`
    },
    {
        heading: "Permissions(if any) :",
        text: (
          <>
            Drone: The client is responsible for obtaining all necessary written permissions for the use of drones at the specified event location, including clearances from the
            local police administration and the venue management. These permissions must be secured prior to the event and provided to {company}. In the event of any dispute or
            issue arising from the use of drones at the venue, {company} shall not be held responsible or liable for any damages, penalties, or refunds. The client assumes full
            responsibility for any legal or regulatory compliance related to drone operations during the event.
            <br />
            <br />
            Religious institutions: The client is solely responsible for obtaining all necessary permissions from the respective religious institutions or venues, such as temples, churches, synagogues, or other locations where the wedding ceremony or event
            will take place. This includes securing authorization for {company} to photograph and/or videograph the ceremony or event. {company} will not be liable for any issues
            arising from the lack of such permissions.
          </>
        ),
    },
    {
        heading: "Point of Contact :",
        text: (
          <>
            The client will designate one point of contact from both the bride's and groom's sides to assist and guide {company}'s team during the event. These designated individuals
            will be responsible for identifying and introducing close family members and important friends to ensure that they are appropriately photographed and included in key moments.
            Their cooperation is essential for facilitating smooth communication and capturing the most meaningful aspects of the event.
          </>
        )
    },
    {
        heading: "Artificial Lighting :",
        text: (
          <>
            {company} does not guarantee the quality of images if the artificial lighting provided by the client at the venue is unsuitable or too dim for photography or filming.
            {company} strongly recommends avoiding the use of colored LED lights, flickering lights, or lasers in the ambient, seating, or performance areas during the wedding.
          </>
        )
    },
    {
        heading: "Rescheduling by Client :",
        text: (
          <>
            In the event of any changes to the plans or postponement of the event, {company} will make every effort to accommodate the new dates with the best available team.
            However, any rescheduling requests must be communicated to {company} at least 45 days prior to the original event date.
            <br />
            <br />
            If the wedding date is changed, an additional charge of 20% of the total value of the final package will apply. This charge covers the administrative and logistical adjustments required to accommodate the new schedule.
          </>
        )
    },
    {
        heading: "Content Revisions and Finalization Policy :",
        text: (
          <>
            Each video delivered by {company} includes one round of revisions. Requests for changes must be submitted within 15 days of the delivery date. All requested changes should
            be consolidated and provided at once (within 15 days of delivery) to facilitate a smooth revision process. Any additional rounds of edits beyond the initial revision will incur
            extra charges. If the client does not submit their revision requests within the 15-day period, the project will be considered finalized, and no further modifications will be made.
          </>
        )
    },
    {
        heading: "Liability :",
        text: (
          <>
            {company}, including its agents and third-party associates, will not be held liable for any issues that may arise due to accidents, loss of materials, acts of God, equipment failure,
            or other unforeseen circumstances. This includes, but is not limited to, interference from surrounding devices, access limitations, flash restrictions, or other location-specific constraints
            that may impact the photography.
            <br />
            <br />
            {company} is also not responsible for any loss or interference with the photography resulting from these restrictions. This limitation of liability extends
            to any damage or loss of photographic materials during processing (both film and digital), camera malfunction, postal issues, fire, or other incidents beyond {company}’s control.
            <br />
            <br />
            The client acknowledges and accepts the inherent risks of event photography, which often involves capturing spontaneous and unpredictable moments. By signing this agreement, the client waives any right to legal
            action for missed or lost photographs, including claims for unintentional infliction of emotional distress or negligence. This waiver reflects the client’s understanding and acceptance of the risks
            associated with the nature of event photography work.
          </>
        )
    },
    {
        heading: "Event Coverage and Guest Comfort :",
        text: (
          <>
            To ensure that {company}'s team can capture the event effectively, we kindly request that guests avoid standing in front of or behind the camera. This helps us to maintain the best possible
            quality of photography and videography.
            <br />
            <br />
            Additionally, please note that crowd management is outside the scope of {company}'s responsibilities. We appreciate your understanding and cooperation
            in creating a smooth and enjoyable experience for everyone involved.
            <br />
            <br />
            To ensure the comfort and privacy of your guests, {company} will not cover food and dining areas. We want to respect your
            guests' space and avoid any potential discomfort from being photographed or recorded while they are dining.
            <br />
          </>
        )
    },
    {
        heading: "Substitution :",
        text: (
          <>
            In the event that {company}'s designated photographer is unable to attend the event due to illness or other unforeseen circumstances beyond their control, {company} reserves the right to
            substitute another representative to take the photographs.
            <br />
            <br />
            In such cases, {company} guarantees that the substitute photographer will be a qualified and experienced professional, ensuring the
            continued delivery of high-quality photography services. We will make every effort to ensure that the substitute maintains the same level of competence and professionalism as the original photographer.
          </>
        )
    },
    {
        heading: "Power and Setup Requirements :",
        text: (
          <>
            For the successful setup and operation of our equipment, we require access to a power supply of 5 amps/220-230 volts at each event location. Additionally, a table measuring 4’x2’ is needed to securely
            place and organize our equipment.
          </>
        )
    },
    {
        heading: "Food For Crew :",
        text: (
          <>
            The client must ensure that food and beverages for our crew be arranged in the guest areas and be provided at a level consistent with that of the guests.
          </>
        )
    },
    {
        heading: "Dispute & Resolution :",
        text: (
          <>
            Any breach of this Agreement shall be governed by the provisions of this clause. This Agreement shall be governed by and construed in accordance with the laws of India.
          </>
        )
    },
    {
        heading: "Negotiation :",
        text: (
          <>
            In the event of a dispute, the aggrieved Party shall promptly notify the other Party in writing, specifying the nature of the dispute and any relevant details. The Parties shall then have 30 (thirty) days from the date
            of such notification to engage in negotiations aimed at resolving the dispute. The Parties agree to use their best efforts to resolve any disputes, disagreements, or claims arising out of or in connection
            with this Agreement through amicable negotiations. Both Parties shall engage in good faith discussions and collaborate to reach a mutually acceptable resolution in a timely manner. If the dispute remains
            unresolved at the end of this 30-day period, it shall be escalated to arbitration.
          </>
        )
    },
    {
        heading: "Arbitration :",
        text: (
          <>
            The unresolved dispute after the Negotiation shall be referred to and resolved through arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996, as amended from time to time. The arbitration shall be conducted by a sole arbitrator, who will be appointed by mutual agreement of the
            Parties. If the Parties are unable to agree on the appointment of the arbitrator, the appointment shall be made in accordance with the provisions of the Indian Arbitration and Conciliation Act, 1996. The
            venue for the arbitration proceedings shall be Gurugram, Haryana, India and all arbitration proceedings shall be conducted in the English language. The Parties agree that the arbitration proceedings shall
            be conducted in a manner that is efficient and expeditious. The arbitration award shall be final, binding, and conclusive upon both Parties. The Parties agree to accept and comply with the arbitration award
            without delay. The costs of arbitration, including the arbitrator's fees, shall be borne equally by both Parties unless otherwise directed by the arbitrator.
          </>
        )
    }
  ];
  const termsPDF = [
    {
        heading: "Final Product :",
        text: ` The client acknowledges that they are engaging ${company} for its specialized technical and artistic expertise in photography and videography.`+
              ` As such, ${company} reserves the exclusive right to edit and curate the captured images and videos in a manner that aligns with its professional`+
              ` judgment and creative vision. While ${company} will make every effort to document the event comprehensively, it is understood that not every guest`+
              ` or moment may be captured or included in the final edited images or videos. ${company} does not guarantee that every individual attending the event`+
              ` will be photographed or featured in the final deliverables, as the selection of content is subject to artistic discretion and the practical limitations`+
              ` of the event coverage.`
    },
    {
        heading: "Copyright :",
        text: ` ${company} retains the perpetual right, as granted under the Indian Copyright Act, to use the photographs and videos produced during the event for promotional,`+
              ` marketing, and advertising purposes. This includes, but is not limited to, showcasing the work on ${company}'s official website, social media platforms, portfolios,`+
              ` and in other digital or print media channels. The client acknowledges that these materials may be used without further notification or compensation, and grants`+
              ` ${company} the necessary permissions to utilize the content in this manner. ${company} will ensure that any sensitive or private moments are handled with discretion`+
              ` and respect during the selection and use of images or videos for publicity.`
    },
    {
        heading: "Permissions(if any) :",
        text: ` Drone: The client is responsible for obtaining all necessary written permissions for the use of drones at the specified event location, including clearances from the`+
              ` local police administration and the venue management. These permissions must be secured prior to the event and provided to ${company}. In the event of any dispute or`+
              ` issue arising from the use of drones at the venue, ${company} shall not be held responsible or liable for any damages, penalties, or refunds. The client assumes full`+
              ` responsibility for any legal or regulatory compliance related to drone operations during the event.\n\nReligious institutions: The client is solely responsible for obtaining`+
              ` all necessary permissions from the respective religious institutions or venues, such as temples, churches, synagogues, or other locations where the wedding ceremony or event`+
              ` will take place. This includes securing authorization for ${company} to photograph and/or videograph the ceremony or event. ${company} will not be liable for any issues`+
              ` arising from the lack of such permissions.`
    },
    {
        heading: "Point of Contact :",
        text: ` The client will designate one point of contact from both the bride's and groom's sides to assist and guide ${company}'s team during the event. These designated individuals`+
              ` will be responsible for identifying and introducing close family members and important friends to ensure that they are appropriately photographed and included in key moments.`+
              ` Their cooperation is essential for facilitating smooth communication and capturing the most meaningful aspects of the event.`
    },
    {
        heading: "Artificial Lighting :",
        text: ` ${company} does not guarantee the quality of images if the artificial lighting provided by the client at the venue is unsuitable or too dim for photography or filming.`+
              ` ${company} strongly recommends avoiding the use of colored LED lights, flickering lights, or lasers in the ambient, seating, or performance areas during the wedding.`
    },
    {
        heading: "Rescheduling by Client :",
        text: ` In the event of any changes to the plans or postponement of the event, ${company} will make every effort to accommodate the new dates with the best available team.`+
              ` However, any rescheduling requests must be communicated to ${company} at least 45 days prior to the original event date.\n\nIf the wedding date is changed, an additional`+
              ` charge of 20% of the total value of the final package will apply. This charge covers the administrative and logistical adjustments required to accommodate the new schedule.`
    },
    {
        heading: "Content Revisions and Finalization Policy :",
        text: ` Each video delivered by ${company} includes one round of revisions. Requests for changes must be submitted within 15 days of the delivery date. All requested changes should`+
              ` be consolidated and provided at once (within 15 days of delivery) to facilitate a smooth revision process. Any additional rounds of edits beyond the initial revision will incur`+
              ` extra charges. If the client does not submit their revision requests within the 15-day period, the project will be considered finalized, and no further modifications will be made.`
    },
    {
        heading: "Liability :",
        text: ` ${company}, including its agents and third-party associates, will not be held liable for any issues that may arise due to accidents, loss of materials, acts of God, equipment failure,`+
              ` or other unforeseen circumstances. This includes, but is not limited to, interference from surrounding devices, access limitations, flash restrictions, or other location-specific constraints`+
              ` that may impact the photography.\n\n${company} is also not responsible for any loss or interference with the photography resulting from these restrictions. This limitation of liability extends`+
              ` to any damage or loss of photographic materials during processing (both film and digital), camera malfunction, postal issues, fire, or other incidents beyond ${company}’s control.\n\nThe client`+
              ` acknowledges and accepts the inherent risks of event photography, which often involves capturing spontaneous and unpredictable moments. By signing this agreement, the client waives any right to legal`+
              ` action for missed or lost photographs, including claims for unintentional infliction of emotional distress or negligence. This waiver reflects the client’s understanding and acceptance of the risks`+
              ` associated with the nature of event photography work.`
    },
    {
        heading: "Event Coverage and Guest Comfort :",
        text: ` To ensure that ${company}'s team can capture the event effectively, we kindly request that guests avoid standing in front of or behind the camera. This helps us to maintain the best possible`+
              ` quality of photography and videography.\n\nAdditionally, please note that crowd management is outside the scope of ${company}'s responsibilities. We appreciate your understanding and cooperation`+
              ` in creating a smooth and enjoyable experience for everyone involved.\n\nTo ensure the comfort and privacy of your guests, ${company} will not cover food and dining areas. We want to respect your`+
              ` guests' space and avoid any potential discomfort from being photographed or recorded while they are dining.`
    },
    {
        heading: "Substitution :",
        text: ` In the event that ${company}'s designated photographer is unable to attend the event due to illness or other unforeseen circumstances beyond their control, ${company} reserves the right to`+
              ` substitute another representative to take the photographs.\n\nIn such cases, ${company} guarantees that the substitute photographer will be a qualified and experienced professional, ensuring the`+
              ` continued delivery of high-quality photography services. We will make every effort to ensure that the substitute maintains the same level of competence and professionalism as the original photographer.`
    },
    {
        heading: "Power and Setup Requirements :",
        text: ` For the successful setup and operation of our equipment, we require access to a power supply of 5 amps/220-230 volts at each event location. Additionally, a table measuring 4’x2’ is needed to securely`+
              ` place and organize our equipment.`
    },
    {
        heading: "Food For Crew :",
        text: ` The client must ensure that food and beverages for our crew be arranged in the guest areas and be provided at a level consistent with that of the guests.`
    },
    {
        heading: "Dispute & Resolution :",
        text: ` Any breach of this Agreement shall be governed by the provisions of this clause. This Agreement shall be governed by and construed in accordance with the laws of India.`
    },
    {
        heading: "Negotiation :",
        text: ` In the event of a dispute, the aggrieved Party shall promptly notify the other Party in writing, specifying the nature of the dispute and any relevant details. The Parties shall then have 30 (thirty)`+
              ` days from the date of such notification to engage in negotiations aimed at resolving the dispute. The Parties agree to use their best efforts to resolve any disputes, disagreements, or claims arising`+
              ` out of or in connection with this Agreement through amicable negotiations. Both Parties shall engage in good faith discussions and collaborate to reach a mutually acceptable resolution in a timely manner.`+
              ` If the dispute remainsunresolved at the end of this 30-day period, it shall be escalated to arbitration.`
              
    },
    {
        heading: "Arbitration :",
        text: ` The unresolved dispute after the Negotiation shall be referred to and resolved through arbitration`+
              ` in accordance with the Indian Arbitration and Conciliation Act, 1996, as amended from time to time. The arbitration shall be conducted by a sole arbitrator, who will be appointed by mutual agreement of the`+
              ` Parties. If the Parties are unable to agree on the appointment of the arbitrator, the appointment shall be made in accordance with the provisions of the Indian Arbitration and Conciliation Act, 1996. The`+
              ` venue for the arbitration proceedings shall be Gurugram, Haryana, India and all arbitration proceedings shall be conducted in the English language. The Parties agree that the arbitration proceedings shall`+
              ` be conducted in a manner that is efficient and expeditious. The arbitration award shall be final, binding, and conclusive upon both Parties. The Parties agree to accept and comply with the arbitration award`+
              ` without delay. The costs of arbitration, including the arbitrator's fees, shall be borne equally by both Parties unless otherwise directed by the arbitrator.`
    }
  ];
  const data = isPDF ? termsPDF : termsHTML;
  return data;
}

export function areObjectsEqual (obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key)) return false;

    if (obj1[key].length !== obj2[key].length) return false;

    const photoIds1 = obj1[key].map(item => item.photo_id);
    const photoIds2 = obj2[key].map(item => item.photo_id);

    photoIds1.sort();
    photoIds2.sort();

    for (let i = 0; i < photoIds1.length; i++) {
      if (photoIds1[i] !== photoIds2[i]) return false;
    }
  }

  return true;
};

export function getMobileOperatingSystem() {
  if (typeof window === "undefined") {
    return "unknown";
  }
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  return "unknown";
}

export function canvasToDataURL(obj, width, height) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(obj, 0, 0, canvas.width, canvas.height);
  const imageDataURL = canvas.toDataURL('image/png');
  return imageDataURL
}

export function catchError(error) {
  if (error.response && error.response.status === 401) {
    getLoginPage();
  } else {
      console.error("Error:", error);
  }
}

export function getApiUrlFromSelectionType(selectionType) {
  const baseUrl = process.env.REACT_APP_STAG_URL;
  switch (selectionType) {
      case 'hide':
          return `${baseUrl}/api/work/multiple/private/replace/`;
      case 'download':
          return `${baseUrl}/api/work/url/selected_imgs/`;
      case 'addTo':
            return `${baseUrl}/api/work/my/modify/`;
      case 'delete_guest_gallery':
            return `${baseUrl}/api/work/guest/gallery/delete/`
      default:
          throw new Error(`Invalid selection type: ${selectionType}`);
  }
}

export function checkPlurality(array, string){
  if(array.length > 1){
    return `${array.length} ${string}s`
  }else{
    return `${array.length} ${string}`
  }
}

export function detectBrowser() {
  const userAgent = navigator.userAgent;

  if (userAgent.includes('Chrome') && !userAgent.includes('Edg') && !userAgent.includes('OPR')) {
    return 'Chrome';
  } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    return 'Safari';
  } else if (userAgent.includes('Firefox')) {
    return 'Firefox';
  } else if (userAgent.includes('Edg')) {
    return 'Edge';
  } else {
    return 'Chrome';
  }
}

export function get_more_logo(type) {
  const albumSelection = 'album_selection'
  const guestUploads = 'guest_gallery'
  const shareUrl = 'share_url'
  if(type===guestUploads){
    return uploadIcon
  }else if(type==shareUrl){
    return shareIcon
  }else if(type==albumSelection){
    return albumIcon
  }else{
    return ''
  }
}