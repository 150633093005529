import React from 'react'
import './Sidebar.css'
import { AiOutlineClose, AiOutlineUser } from 'react-icons/ai'
import { GrTransaction, GrUserAdmin, GrUnorderedList, GrInstagram } from "react-icons/gr"
import { MdLogout, MdOutlineEdit } from "react-icons/md"
import { useState, useEffect } from 'react';
import { useStateContext } from '../../context/StateContext';
import { getLogoutFunctionality, isAdmin, token_url, globalLoader, Platform } from '../../script/util.js';

const Sidebar = () => {
  const { isOpen, setisOpen } = useStateContext();
  const [user, setUser] = useState("loading..")
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('auth_dict')))
  }, [])
  const handleLogout = () => {
    setLoading(true)
    getLogoutFunctionality();
  }
  var inst_url = 'https://www.instagram.com/eventgraphia/'
  if (Platform.isWG) {
    inst_url = 'https://www.instagram.com/wedgraphia/'
  }
  return (
    <>
      {isOpen &&
        <>
          <div className="sidebar-overlay" onClick={() => setisOpen(false)}></div>
          <div className="sidebar">
            <AiOutlineClose className='close-icon-sidebar' onClick={() => { setisOpen(false) }} />
            <div className="userDetails">
              <div className="top-sidebar">
                <li className="sidebar-sub-heading">Account</li>

                <li className="sub-links-sidebar"><p><AiOutlineUser />{user.eventGraphiaName}</p></li>

              </div>
              <div className="middle-sidebar">
                <li className="sidebar-sub-heading">Profile</li>
                <li className="sub-links-sidebar pointer" onClick={() => !isAdmin() ? window.location = token_url(`${process.env.REACT_APP_STAG_URL}/account`) : ""}><p><MdOutlineEdit />Edit Profile</p> </li>
                <li className="sub-links-sidebar pointer" onClick={() => window.location = token_url(`${process.env.REACT_APP_STAG_URL}/transactions`)}><p><GrTransaction />My Transactions</p></li>
              </div>
              <div className="bottom-sidebar">
                <li className="sidebar-sub-heading">Others</li>
                <li className="sub-links-sidebar pointer" onClick={() => window.open(inst_url, '_blank', 'noopener, noreferrer')}><p><GrInstagram />Our Instagram</p></li>

                <li className="sub-links-sidebar pointer" onClick={() => window.location = token_url(`${process.env.REACT_APP_STAG_URL}/terms`)}><p><GrUnorderedList />Terms & Conditions</p></li>

                <li className="sub-links-sidebar pointer" onClick={() => window.location = token_url(`${process.env.REACT_APP_STAG_URL}/privacy`)}><p><GrUserAdmin />Privacy Policy</p></li>

                <li onClick={handleLogout} className="sub-links-sidebar pointer"><p><MdLogout />Logout{loading && globalLoader('md')}</p></li>

              </div>
            </div>
          </div>
        </>}
    </>

  )
}

export default Sidebar